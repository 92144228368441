<template>
  <div class="OwnerConsultList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="false"
    >
      <template #searchSlot>
        <v-input label="发送人" v-model="searchParams.sendUserName"></v-input>
        <v-input label="接收人" v-model="searchParams.reciveUserName"></v-input>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select2 v-if="getShowRegionName()" label="所属公司" v-model="searchParams.regionId" v-bind="regionParams"></v-select2>
        <v-datepicker label="发送时间段" :maxDate="maxDate" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL } from './api'
import { communityParams, regionParams } from '@/common/select2Params'

export default {
  name: 'OwnerConsultList',
  data () {
    return {
      searchUrl: getListURL,
      communityParams,
      regionParams,
      maxDate: new Date(),
      searchParams: {
        sendUserName: '',
        reciveUserName: '',
        communityId: '',
        regionId: '',
        startTime: '',
        endTime: ''
      },
      headers: [
        {
          prop: 'sendUserName',
          label: '发送人'
        },
        {
          prop: 'lastContent',
          label: '最新内容'
        },
        {
          prop: 'reciveUserName',
          label: '接收人'
        },
        {
          prop: 'communityName',
          label: '所在项目'
        },
        {
          prop: 'lastChangeTime',
          label: '最新发送时间'
        },
        {
          prop: 'description',
          label: '所属公司',
          show: this.getShowRegionName()
        }
      ]
    }
  },
  methods: {
    // 根据账户角色权限，获取是否要显示所属分公司
    getShowRegionName () {
      let userInfo = this.$store.state.userInfo
      let show = false
      if (userInfo) {
        if (userInfo.userType && (userInfo.userType === '100' || userInfo.userType === '106')) {
          show = true
        }
      }
      return show
    },
    edit (row) {
      this.$router.push({
        name: 'ownerConsultForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
